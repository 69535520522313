import { ReactNode } from 'react';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import {
  PageViewTracker,
  BASE_PATHS,
  COHERE_CONFIG,
  getRandomSampleFromArray,
  kebabCase,
  futureUnixTimestamp,
  schemaData,
  COOKIES_CONFIG,
} from '@belong/common';
import { Text, TestimonialsCarousel, GoogleStars, VideoSideContents, BulletList, LinkBase, Footer } from '@belong/ui';
import clsx from 'clsx';
import { parseCookies } from 'nookies';
import { fetchCityListService } from 'src/api/geo';
import { fetchFeaturedCitiesService, setFeaturedCitiesService } from 'src/api/homes';
import { FeaturedCities } from 'src/api/models';
import { CohereWidgetProps } from 'src/components/cohere-widget/cohere-widget';
import { Header } from 'src/components/header/header';
import { MetaSEO } from 'src/components/meta-seo/meta-seo';
import { Cover } from 'src/features/home-page/components/Cover/Cover';
import { FaqSection } from 'src/features/home-page/components/FaqSection/FaqSection';
import { HomeCta } from 'src/features/home-page/components/HomeCta/HomeCta';
import { ReferralCard } from 'src/features/home-page/components/ReferralCard/ReferralCard';
import { SocialProof } from 'src/features/home-page/components/social-proof/social-proof';
import { HOME_CTA_PROPS } from 'src/features/home-page/constants';
import { HOME_PAGE_STRINGS } from 'src/features/home-page/home-page.strings';
import styles from 'src/features/home-page/home.module.css';
import { StaticLandingPageLink } from 'src/features/homeowners/homeowners.types';

const CohereWidget = dynamic<CohereWidgetProps>(
  () => import('src/components/cohere-widget/cohere-widget').then((mod) => mod.CohereWidget),
  { ssr: false }
);

const metaDescription = HOME_PAGE_STRINGS['meta.description'] as string;
const metaTitle = HOME_PAGE_STRINGS['meta.title'] as string;

type HomePageProps = { featuredSet: StaticLandingPageLink[] };
// TODO: MOVE ANALYTICS PAGE NAMES TO COMMON
export default function IndexPage({ featuredSet }: HomePageProps) {
  return (
    <PageViewTracker name="home_page">
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />
      </Head>
      <MetaSEO
        title={metaTitle}
        description={metaDescription}
        openGraph={{
          description: metaDescription,
          type: 'video.other',
          url: 'https://s3-us-west-2.amazonaws.com/belong-web-assets/Belong.mp4',
        }}
      />
      <CohereWidget scrollHeight={150} isEnabled={COHERE_CONFIG.homeowner.isEnabled} />
      <div className={clsx('bg-white md:mt-0 container flex justify-end relative h-xs', styles.mTop)}>
        <Text as="h1" variant="p2" className="text-dark-gray absolute bottom-xs">
          Top Rated Property Management for Landlords and Renters
        </Text>
      </div>

      <div className="w-full mb-2xl">
        <Cover title={HOME_PAGE_STRINGS['header.title']} subtitle={HOME_PAGE_STRINGS['header.subtitle']} />
      </div>

      <div className="container mb-5xl lg:mb-6xl">
        <SocialProof />
      </div>

      <div className="container flex justify-center mb-xl md:mb-2xl">
        <Text variant="h2" fontWeight="semibold" className={clsx('text-center', styles.videoTitle)}>
          {HOME_PAGE_STRINGS.videoTitle}
        </Text>
      </div>
      <div className="flex justify-center flex-1 mb-5xl md:mb-6xl">
        <VideoSideContents />
      </div>
      <div className="container flex flex-col items-center mb-5xl md:mb-6xl">
        <div className="w-full">
          <div className="w-full mb-2xl">
            <HomeCta {...HOME_CTA_PROPS[0]} />
          </div>
          <div className="w-full mb-2xl">
            <HomeCta {...HOME_CTA_PROPS[1]} />
          </div>

          <HomeCta {...HOME_CTA_PROPS[2]} />
        </div>
      </div>

      <div className="container flex flex-col items-center">
        <Text variant="h2" fontWeight="semibold" className="text-center mb-md">
          {HOME_PAGE_STRINGS.testimonialsTitle}
        </Text>
        <GoogleStars />
      </div>

      <div className="carousel-container flex justify-center my-xl md:mt-2xl md:mb-3xl">
        <TestimonialsCarousel />
      </div>

      <div className="container flex justify-center mt-3xl mb-6xl">
        <ReferralCard />
      </div>

      <div className="container mb-xl">
        <FaqSection />
      </div>

      <div className="container text-dark-gray  mb-3xl lg:mb-4xl">
        <Text variant="p1" fontWeight="semibold" className="text-dark-gray mb-xs">
          {HOME_PAGE_STRINGS['featuredCities.title']}
        </Text>
        <BulletList
          wrap
          size="large"
          items={featuredSet.map(({ href, displayName }) => {
            return (
              <LinkBase key={href} href={href} className="p1 hover:text-dark-navy leading-h2-sm">
                {displayName}
              </LinkBase>
            );
          })}
        />
      </div>
    </PageViewTracker>
  );
}

const CITY_PAGES_AMOUNT = 25;
const FIXED_CITIES = [
  { city: 'Oakland', stateId: 'CA' },
  { city: 'Seattle', stateId: 'WA' },
  { city: 'Redmond', stateId: 'WA' },
];

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  let featuredSetResponse: FeaturedCities;

  try {
    featuredSetResponse = await fetchFeaturedCitiesService();
  } catch (e) {
    console.error(e);
  }

  // @ts-ignore
  let featuredSet = featuredSetResponse?.data || [];
  if (featuredSet.length > 0) {
    return {
      props: { featuredSet },
    };
  }

  try {
    const coverageCities = await fetchCityListService();

    const citiesWithoutFixedCities = coverageCities.filter((city) => {
      return !FIXED_CITIES.some((fixedCity) => {
        return fixedCity.city === city.city && fixedCity.stateId === city.stateId;
      });
    });

    const featuresCities = getRandomSampleFromArray({
      array: citiesWithoutFixedCities,
      sampleSize: CITY_PAGES_AMOUNT - FIXED_CITIES.length,
    });

    const formattedFeaturesCities = [...FIXED_CITIES, ...featuresCities].map(
      (city: { city: string; stateId: string }) => ({
        href: `${BASE_PATHS.HOMEOWNER}/${kebabCase(city.city)}-${city.stateId.toLowerCase()}-property-management`,
        displayName: `${city.city}`,
      })
    );

    formattedFeaturesCities.sort((a, b) => a.displayName.localeCompare(b.displayName));
    featuredSet = formattedFeaturesCities;

    await setFeaturedCitiesService({
      data: featuredSet,
      expiresAt: futureUnixTimestamp(14),
    });
  } catch (e) {
    console.log(e);
  }

  return {
    props: { featuredSet },
  };
};

IndexPage.getLayout = (page: ReactNode): ReactNode => {
  const cookies = parseCookies();

  const comeFromMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');
  return (
    <div className="relative flex flex-col min-h-full overflow-x-hidden">
      {!comeFromMobileApp && (
        <div className="relative z-10">
          <Header variant="tertiary" />
        </div>
      )}
      <main className="flex-1">{page}</main>
      <Footer />
    </div>
  );
};

import React from 'react';
import Head from 'next/head';
import { BASE_PATHS } from '@belong/common';
import { Image, Text, Link } from '@belong/ui';
import clsx from 'clsx';
import { HOME_PAGE_STRINGS } from '../../home-page.strings';
import css from './Cover.module.css';

const backgroundElements = [
  {
    className: css.cloud1,
    src: '/homeowner-lp/career_landing_cloud_01.svg',
  },
  {
    className: css.cloud2,
    src: '/homeowner-lp/career_landing_cloud_01.svg',
  },
  {
    className: css.cloud3,
    src: '/homeowner-lp/career_landing_cloud_02.svg',
  },
  {
    className: css.cloud4,
    src: '/homeowner-lp/career_landing_cloud_01.svg',
  },
  {
    className: css.balloon1,
    src: '/homeowner-lp/career_landing_balloon_01.svg',
  },
  {
    className: css.balloon2,
    src: '/homeowner-lp/career_landing_balloon_02.svg',
  },
  {
    className: css.plane,
    src: '/homeowner-lp/career_landing_plane.svg',
  },
];

type CoverPageProps = {
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  showDecorations?: boolean;
};

export function Cover({ title, subtitle, showDecorations = true }: CoverPageProps) {
  return (
    <div className={clsx('w-full relative', css.headerContainer)}>
      <div className="relative pointer-events-none">
        <div className={clsx('absolute top-0 inset-x-0 flex justify-center', css.bgElementsContainer)}>
          {showDecorations && (
            <div className="relative w-full mt-xl md:mt-0 lg:w-0">
              {/* mt in this div is a nice hacky solution for moving all bg-elements down */}
              {backgroundElements.map((el, idx) => (
                <div key={idx} className={clsx('absolute', el.className)}>
                  <Image src={el.src} alt="" />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="relative container flex flex-col items-center pt-xl mb-2xl md:mb-0">
        <div className="mb-xs md:mb-sm">
          <Text as="h2" variant="h1" fontWeight="semibold" className="text-center">
            {title}
          </Text>
        </div>
        <div className="mb-xl">
          <Text variant="h3" className={css.subtitle}>
            {subtitle}
          </Text>
        </div>
        <div className="flex justify-center w-full">
          <div className="flex-1 mr-xs sm:flex-none md:mr-sm">
            <Link
              data-testid="homeowners-cta"
              href={BASE_PATHS.HOMEOWNER}
              variant="solid"
              size={{ _: 'fluid', sm: 'default' }}
              className="text-center"
            >
              {HOME_PAGE_STRINGS['header.ownersCta']}
            </Link>
          </div>
          <div className="flex-1 mr-xs sm:flex-none md:mr-sm">
            <Link href={BASE_PATHS.HOME} variant="solid" size={{ _: 'fluid', sm: 'default' }} className="text-center">
              {HOME_PAGE_STRINGS['header.rentersCta']}
            </Link>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center absolute bottom-0 -z-10">
        <Head>
          {/* @ts-ignore */}
          <link
            rel="preload"
            as="image"
            href="https://belonghome.imgix.net/home-page/home_landing_header_2x.v2.jpg?ixlib=js-3.6.0&auto=format"
          />
        </Head>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="https://belonghome.imgix.net/home-page/home_landing_header_2x.v2.jpg?ixlib=js-3.6.0&auto=format"
          alt=""
          style={{ height: 370, maxWidth: 2000 }}
          className={clsx('w-full object-cover object-center', css.coverImage)}
        />
      </div>
    </div>
  );
}
